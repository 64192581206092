import {
    Button,
    Group,
    PanelHeader,
    PanelHeaderBack,
    PanelHeaderButton,
    Placeholder,
    platform
} from "@vkontakte/vkui";
import {Icon24MessagesOutline, Icon28MessageOutline} from "@vkontakte/icons";
import {getAuth, signOut} from "firebase/auth";
import {wwwExtention} from "../CommonHelper";
import React from 'react';
import {strings} from "../localization";
import {doSignOut} from "../firebase";

function Login({goBack}) {
    //выйти по кнопке из аккаунта


    return (<div>
        <PanelHeader
            before={<PanelHeaderBack label="Назад" onClick={() => goBack()}/>}
            after={
                <PanelHeaderButton>
                    {/*<Icon28MessageOutline*/}
                    {/*    width={platform === Platform.VKCOM ? 24 : 28}*/}
                    {/*    height={platform === Platform.VKCOM ? 24 : 28}*/}
                    {/*/>*/}
                </PanelHeaderButton>
            }
        >
            {strings.account}
        </PanelHeader>

        <Group>
            <Placeholder
                icon={<Icon24MessagesOutline/>}

            >

                <br/> <br/>
                <a style={{marginBottom: "120px"}} href="https://t.me/enevator">{strings.feedback}</a>

                <p>
                    <a style={{marginBottom: "30px"}} href="mailto:support@enapp.ru">support@enapp.ru</a>
                </p>
                <br/> <br/> <br/> <br/>
                <Button size="m" onClick={() => doSignOut()}>{strings.signout}</Button>
            </Placeholder>


        </Group>
    </div>);
}

export default Login;

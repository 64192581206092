import {kslog} from "./CommonHelper";
import React from 'react';
// import {authFB} from './firebase';
import {getAuth} from "firebase/auth";
export class DBManager extends React.Component {
    getTags (){
        this.sendRequestToServerUrl({"op":"get_tags"})
    }
    getListNotes (type){
        this.sendRequestToServerUrl({"op":"get_list_notes", "type":type})

    }
    deleteNote (id_note,type){
        this.sendRequestToServerUrl({"op":"delete_note",id_note:id_note, "type":type})
    }

    getNote (id_note){
        this.sendRequestToServerUrl({"op":"get_note",id_note:id_note})
    }

    //обновляем пользователя
    updateEnevoin(email, name, firebase_uid) {
        let jsonData = {};
        jsonData["nm"] = name;
        jsonData["op"] = "u_en";
        jsonData["uid_f"] = firebase_uid;
        jsonData["em"] = email;

        this.sendRequest(jsonData, "u_en");
    }
    //ЗАПРОС С ТОКЕНОМ
    sendRequestToServerUrl(jsonData) {
        kslog("sendRequestToServerUrl ", JSON.stringify(jsonData));
        let that=this;
        let user=getAuth().currentUser;
        user && user.getIdToken().then(function (data) {
            jsonData.ie=parseInt(getAuth().currentUser.photoURL);
            jsonData.tn=data;
            that.sendRequest(jsonData);
        });
    }
    sendRequest(jsonData) {
        let fullUrl="https://enapp.ru/webnotes/api/webnotes.php";

        kslog("sendRequest no token ", JSON.stringify(jsonData));
        fetch(fullUrl, {
            method: "POST",
            body: JSON.stringify(jsonData)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                //  kslog("responseJson" , JSON.stringify(responseJson));
                this.didGetResponse(responseJson);
            })
            .catch((error) => {
                console.error("error " , error);
            });
    }
    //
    // sendFileWithToken(jsonData) {
    //
    //     kslog("sendFileWithToken fullUrl", fullUrl);
    //     const formData = new FormData();
    //     // formData.append(ID_ENEVOIN, jsonData[ID_ENEVOIN]);
    //     formData.append(TOKEN, jsonData[TOKEN]);
    //
    //     fetch(fullUrl, {
    //         method: "POST",
    //         // mode: "no-cors",
    //         body: formData
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             kslog("responseJson", responseJson);
    //             this.didGetResponse(responseJson);
    //         })
    //         .catch((error) => {
    //             console.error("error " + error);
    //         });
    // }
    //

    didGetResponse (responseJson){
        let op=responseJson.op;
        kslog("didGetResponse",responseJson)
        switch (op) {
            case "get_note":
                this.props.callbackGetNote(responseJson);
                break;
            case "get_list_notes":
            case "delete_note":
                this.props.callbackGetListNotes(responseJson);
                break;
            case "get_tags":
                this.props.callbackGetTags(responseJson);
                break;
            case "u_en":
                this.props.callbackEnevoinUpdated(responseJson);
                break;
        }

    }
    render() {
        return (
            <div>
            </div>
        );
    }
}

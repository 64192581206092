import React, {useEffect, useRef, useState} from 'react';

import {
    Panel,
    PanelHeader,
    Header,
    Button,
    Group,
    Cell, unstable_ChipsSelect as ChipsSelect,
    Div, Link,
    Avatar,
    SegmentedControl,
    List, RichCell,IconButton,
    Separator, FixedLayout, FormItem, Spinner, Placeholder, PanelHeaderButton
} from '@vkontakte/vkui';
import {
    Icon16GridOfFour, Icon20MoreVertical,
    Icon24Broadcast,
    Icon24List,
    Icon24MoreHorizontal, Icon28MessageOutline,
    Icon28SettingsOutline
} from "@vkontakte/icons";

import {DBManager} from "../DBManager";
import {kslog, wwwExtention} from "../CommonHelper";
import LocalizedStrings from 'react-localization';
import {strings} from '../localization.js'
function Home({ setSelectedTags, selectedTags,
                  arListNotesSource,loadingListNotes,
                  arTags,arListNotes,setArListNotes,
                  setCurrentObjNote,openDeletion,deleteNote,getDataFromServer,
                  currentType,  setCurrentType
              }) {

    // const [arListNotes, setArListNotes] = useState([]);
    // const [loading, setLoading] = useState(true);
    const dbManager = useRef();





    const tagProps = {
        value: selectedTags,
        onChange: onChangeTag,
        closeAfterSelect: true,
        options: arTags,
        placeholder: strings.filter,
        creatable: false
    };

    //фильтр меток
    function onChangeTag(arChipsTags) {
        let arChipsTagsTmp = arChipsTags.map((t) => t.value);

        kslog("onChangeTag", arChipsTagsTmp);
        kslog("onChangeTag", arListNotesSource);
        if (arChipsTagsTmp.length > 0) {
            const myArrayFiltered = arListNotesSource.filter((el) => arChipsTagsTmp.every(v => el.id_tags.includes(v)));
            setArListNotes(myArrayFiltered);
            kslog("onChangeTag myArrayFiltered", myArrayFiltered);
        } else {
            setArListNotes(arListNotesSource);
        }
        setSelectedTags(arChipsTags);

    }
    //получаем заметки и метки
    function getData() {
        kslog("arListNotesSource", arListNotesSource);
        if (arListNotesSource.length === 0) {
            getDataFromServer(1, true);
        } else {
            onChangeTag(selectedTags);
        }
    }

    useEffect(() => {
        getData();
    }, []);



    //выбрали заметку в списке
    function onClickList(ev, item, index) {


        kslog("onClickList",ev.target.tagName)
        if (ev.target.tagName==="svg" || ev.target.tagName==="BUTTON") {
            openDeletion(item);
        }else {
            let arListNotesTmp = [...arListNotes];
            item.list_notes = arListNotesTmp;
            item.list_index = index;
            setCurrentObjNote(item);
        }

    }

    function onChangeType(value) {
        setCurrentType(value);
        getDataFromServer(value, false);
        setCurrentObjNote(null);
        kslog("onChangeType", value)
    }

    return (<div>
            <Group>
                <SegmentedControl style={{paddingLeft:10,paddingRight:10}}
                    onChange={(value) => onChangeType(value)}
                                  value={currentType}
                    options={[{
                        'label': strings.video,
                        'value': 1, 'aria-label': strings.video,
                    }, {
                        'label': strings.text,
                        'value': 0, 'aria-label': strings.text,
                    },
                    ]}
                />
                <FormItem>
                    <ChipsSelect  closeAfterSelect={false} readOnly {...tagProps} />
                </FormItem>
            </Group>
            {loadingListNotes === true
                ? <Spinner size="medium" style={{margin: '20px 0'}}/>
                :
                <Group>
                    {arListNotes.length === 0
                        ? <Placeholder
                            icon={<Icon24MoreHorizontal/>}>
                            {strings.no_records}
                            {arListNotesSource.length === 0
                                ? <p>
                                    {strings.create_note_with} <a target="_blank"
                                                                   href={wwwExtention}>
                                    {strings.chrome_ext}
                                   </a>
                               </p>
                                 : null}
                        </Placeholder>

                        :
                        <div id= {arListNotes.length>10 ? "list-notes" : null} >
                        <List>
                            {arListNotes.map((item, index) => {
                                return <Cell
                                    multiline
                                    caption={item.link}
                                    key={index}
                                    after={
                                        <IconButton aria-label="more" id="btn-line-more">
                                            <Icon20MoreVertical id="btn-icon-more"/>
                                        </IconButton>
                                    }
                                    before={item.type === 1 ? <img id="image_video"
                                                                   src={"https://i.ytimg.com/vi/" + item.link + "/hqdefault.jpg"}/> : null}
                                    onClick={(e) => onClickList(e, item, index)}> {item.title}

                                </Cell>
                            })}
                        </List>
                        </div>
                    }


                </Group>
            }

            <DBManager ref={dbManager} />
        </div>

    )


}

export default Home;

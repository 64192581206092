import React, {useRef, useState} from "react";

import {Gallery, Placeholder} from "@vkontakte/vkui";
import {Icon24List} from "@vkontakte/icons";
import ReactQuill from "react-quill";
import {goScrollTop, kslog} from "../CommonHelper";
import {strings} from "../localization";


function TextNote({arDataPages,arTimes, timeIndex, setTimeIndex}) {
    const [value, setValue] = useState('');
    const refQuill = useRef();
    const modules = {
        toolbar: false
    }

    //сменили страницу заметок
    function onChangePage(page) {
        kslog("onChangePage",page)
        goScrollTop();
        if (page < arTimes.length){
            setTimeIndex(page);
        }else {
            setTimeIndex(0);
        }

    }

    return (<div>
            {arDataPages.length > 0
                ?

                <Gallery slideWidth="100%" align="right"
                         slideIndex={timeIndex < arTimes.length ? timeIndex : 0}
                         onChange={(page) => onChangePage(page)}>
                    {arDataPages.map((page, index) => {

                        return <div key={index}>
                            <ReactQuill
                                readOnly
                                modules={modules}
                                toolbar={false} ref={refQuill} theme="snow" value={page} onChange={setValue}/>
                        </div>
                    })}
                </Gallery>
                :
                <Placeholder
                    icon={<Icon24List/>}>
                    {strings.chooseNote}
                </Placeholder>
            }

        </div>
    );
}


export default TextNote;

import {isMobile} from "react-device-detect";

export const wwwExtention ="https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm";
export function kslog(message, desc) {
    //1===1 ||

    let param = window.location.search.substring(1);
    // kslog("param=", param);
    param ="kslog";
    if (param && param === "kslog") {
        // if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {//|| app_id===6991429
        if (desc === undefined) desc = "";
        console.log("=" + message, desc);
    }
}
export function prepareTimesAndPages (jsonNote){
    let objJsonLines = JSON.parse(jsonNote).ops;
    let objJsonNewLines = [];
    let arPages = [];
    let arTimesTmp = [];
    let indexTime = 0;
    let oneNextTmp = "";
    for (let i = 0; i < objJsonLines.length; i++) {
        let oneTmp = objJsonLines[i];

        //убираем перенос от метки времени в начале
        if (objJsonNewLines.length === 0 && oneTmp.insert) {
            oneTmp.insert = oneTmp.insert.trimStart();
        }
        if (oneTmp.attributes !== undefined && oneTmp.attributes.mtime !== undefined) {

            if (objJsonNewLines.length > 0) {
                if (isMobile) objJsonNewLines.push({insert: "\n\n\n\n"})
                let ops = {ops: objJsonNewLines};
                arPages.push(ops);
            }
            // kslog("test arPages", objJsonNewLines)

            let oneTime = {};
            oneTime.time_sec = oneTmp.attributes.mtime;
            // kslog("objJsonNewLines", objJsonNewLines)

            for (let j = i + 1; j < objJsonLines.length; j++) {
                oneNextTmp = objJsonLines[j].insert.replace(/\n/g, "").trim();

                if (oneNextTmp.length > 0) {
                    break;
                }
            }
            oneNextTmp = (i < objJsonLines.length ? oneNextTmp : "end");

            oneTime.label = oneTime.time_sec.toHHMMSS() + " " + oneNextTmp.substr(0, 50);
            oneTime.time_str = oneTime.time_sec.toHHMMSS();
            oneTime.value = indexTime;
            arTimesTmp.push(oneTime);
            indexTime++;
            // kslog("atrr", oneTmp.mtime)

            objJsonNewLines = [];
        } else {
            objJsonNewLines.push(oneTmp);
            // kslog("arPages aa", objJsonNewLines)
            if (arTimesTmp.length === 0) {
                let startTmp = {
                    time_sec: "300",
                    label: "00:00 " + objJsonNewLines[0].insert,
                    time_str: "00:00",
                    value: 0
                };
                indexTime = 1;
                arTimesTmp.push(startTmp)
            }
        }

    }
    if (isMobile) objJsonNewLines.push({insert: "\n\n\n\n"})
    let ops = {ops: objJsonNewLines};
    arPages.push(ops);
    kslog("prepare arTimesTmp", arTimesTmp)
    kslog("prepare arPages", arPages)
    return [arTimesTmp, arPages];
}
export function convertIdTagsToArray (arRes){
    let arListNotesTmp = arRes.map((item) => {
        if (item.id_tags === null) {
            item.id_tags = [];
        } else {
            let arIdTags = item.id_tags.toString().split(",");
            arIdTags = arIdTags.map(Number)
            item.id_tags = arIdTags;
        }
        return item;
    })
    return arListNotesTmp;
}
export function prepareTagsForSelect (arTagsTmp){


    arTagsTmp = arTagsTmp.map((objTag) => {
        return {label: objTag.tag, value: objTag.id};
    });
    return arTagsTmp;
}
export    function goScrollTop (){
    let divNotes = document.getElementById('div-notes');
    kslog("divNotes",divNotes);
    if (divNotes) divNotes.scrollTo(0, 0);
}


String.prototype.toHHMMSS = function () {
    let sec_num = parseInt(this, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (hours!=="00") {
        return hours+':'+minutes+':'+seconds;
    }else {
        return minutes+':'+seconds;
    }
}

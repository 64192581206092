import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {initializeApp} from "firebase/app"

import firebase from "firebase/compat/app";
// const prodConfig = {
//   apiKey: "AIzaSyDbUy5NKnHZ2nDMyi_3HERTvwfi9BtbY_Q",
//   authDomain: "testreact-8cd78.firebaseapp.com",
//   databaseURL: "https://testreact-8cd78.firebaseio.com",
//   projectId: "testreact-8cd78",
//   storageBucket: "testreact-8cd78.appspot.com",
//   messagingSenderId: "166691096868"
// };

const firebaseConfig = {
    apiKey: "AIzaSyBWXQXLSOZg7e8C-n_Eh0WsjeIr3mhwEnQ",
    authDomain: "webnotes-4dc5b.firebaseapp.com",
    projectId: "webnotes-4dc5b",
    storageBucket: "webnotes-4dc5b.appspot.com",
    messagingSenderId: "169675226852",
    appId: "1:169675226852:web:9aca9989322c2ae30176f1",
    measurementId: "G-ME7CRENLTK"
};
// firebase.setLogLevel('silent');
const firebaseApp = initializeApp(firebaseConfig)
// const app = firebase.initializeApp(prodConfig);
firebase.setLogLevel('silent');

export default firebaseApp;
// export const fb = firebase;
// export const authFB = getAuth();// app.auth();
export const currentUser = getAuth().currentUser;

export function doSignOut() {

    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

// chrome.commands.onCommand.addListener(function (command) {
//     if (command === "save") {
//         alert("save");
//     } else if (command === "random") {
//         alert("random");
//     }
// });
// //---------Replace This with your Own SDK-------->
// alert("aaa 33")
// kslog('message sent 1');
// chrome.action.onClicked.addListener(tab => {
//     chrome.tabs.sendMessage(tab.id,"toggle");
//     kslog('message sent');
//     alert("bbb 33")
// });
//

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
//
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyBWXQXLSOZg7e8C-n_Eh0WsjeIr3mhwEnQ",
//     authDomain: "webnotes-4dc5b.firebaseapp.com",
//     projectId: "webnotes-4dc5b",
//     storageBucket: "webnotes-4dc5b.appspot.com",
//     messagingSenderId: "169675226852",
//     appId: "1:169675226852:web:9aca9989322c2ae30176f1",
//     measurementId: "G-ME7CRENLTK"
// };
//
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

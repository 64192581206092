import React, {useEffect, useRef, useState} from "react";
import {
    Button, Footer,
    FormItem,
    FormLaayout, FormLayoutGroup,
    Group, Input, Link, ModalPage, ModalRoot,
    Placeholder, FormLayout,
    Separator, Spinner,
    SplitCol,
    SplitLayout, Title, PanelHeader
} from "@vkontakte/vkui";

// import {authFB} from "firebase";
// import DBManager from "../data/DBManager";
import {strings} from '../localization.js'
import {kslog} from "../CommonHelper";

  const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
  const ERROR_CODE_ACCOUNT_NOT_FOUND = 'auth/user-not-found';
  const ERROR_CODE_WRONG_PSW = 'auth/wrong-password';
  const ERROR_CODE_WRONG_EMAIL = 'auth/invalid-email';
  const ERROR_CODE_TOO_MANY_REQUEST = 'auth/too-many-requests';

import {
    getAuth,
    sendPasswordResetEmail,
    updateProfile,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword
} from "firebase/auth";

import firebase from "firebase/compat";
import {DBManager} from "../DBManager";
// import {authFB} from "../firebase.js";


const BLOCK_LOGIN = 'login';
const BLOCK_NEW_ACCOUNT = 'new_account';
const BLOCK_RESET_PSW = 'reset_psw';
const BLOCK_DID_RESET_PSW = 'did_reset_psw';

//ОКНО ЛОГИН
function Login({doUser, setPrivacy}) {

    const dbManager = useRef();
    const [currentBlock, setCurrentBlock] = useState(BLOCK_LOGIN);
    const [modalWindow, setModalWindow] = useState(null);
    const [textSogl, setTextSogl] = useState("");
    const [textPolicy, setTextPolicy] = useState("");
    const [newAccountName, setNewAccountName] = useState("");
    const [email, setEmail] = useState("");
    const [psw, setPsw] = useState("");
    const [newAccountPswRepeat, setNewAccountPswRepeat] = useState("");

    const [isWaiting, setIsWaiting] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [pswError, setPswError] = useState(null);


    useEffect(() => {
        firebase.setLogLevel('silent');

        if (process.env.NODE_ENV === "development") {
            kslog("development")
            setEmail("test@mail.ru");
            setPsw("123456");
            setNewAccountPswRepeat("123456");
            setNewAccountName("test")
        }
        //горячие клавишы
        function hotKeyCal(event) {
            let key = event.key.toUpperCase();
            if (key === "ENTER" && currentBlock === BLOCK_LOGIN) {
                document.getElementById('btn_login').click();
            }
        }

        window.addEventListener("keydown", hotKeyCal)
        return () => {
            window.removeEventListener("keydown", hotKeyCal)
        }



    }, []);




    function isEmailString(str) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(str);
    }
    //получаем текст соглашения/
    useEffect(() => {
        if (textSogl.length === 0 && currentBlock === BLOCK_NEW_ACCOUNT) {
            getDataUserDocs("sogl")
            getDataUserDocs("policy")
        }
        setPswError(null);
        setEmailError(null);
        setIsWaiting(false)
    }, [currentBlock])

    //тест
    function login_admin() {
        firebaseLogin("kosb@bk.ru", "123456");
    }

    //логинимся
    function login() {
        kslog("login",  dbManager.current);

        let emailTmp = email;
        let pswTmp = psw;
        if (process.env.NODE_ENV === "development") {
            if (emailTmp.length === 0) { //DEMO DEBUG
                emailTmp = "kosb@bk.ru";
                pswTmp = "123456";
            }
        }
        if (isEmailString(emailTmp) === true) {
            firebaseLogin(emailTmp, pswTmp);
        } else {
            setEmailError(strings.wrong_email);
        }
    }

    //создаем аккаунт
    function createNewAccount(dbMan) {
        setPswError(null);
        setEmailError(null);

        if (psw !== newAccountPswRepeat) {
            setPswError(strings.dif_passwords);
            return;
        }
        if (isEmailString(email) === false) {
            setEmailError(strings.wrong_email);
            return;
        }
        if (psw.length < 6) {
            setPswError(strings.psw_more_6);
            return;
        }

        setIsWaiting(true);
        localStorage.clear();


        createUserWithEmailAndPassword(getAuth(), email, psw)
            .then((userCredential) => {
                //вошли
                let createdUser = userCredential.user;
                kslog("createUse", createdUser);
                //обновляем ИМЯ
                updateProfile(createdUser, {
                    displayName: newAccountName
                }).then(() => {
                    kslog("name changed");
                }).catch((error) => {
                    kslog(error);
                });

                //создаем в базе - получаем id_enevoin
                dbMan.updateEnevoin(email, newAccountName, createdUser.uid);

            })
            .catch((error) => {
                setIsWaiting(false)
                if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                    setEmailError(strings.email_exits)
                }
                kslog("created errorMessage", error.message)
            });
    }

    //сброс пароля (через почту)
    function resetPsw() {
        if (isEmailString(email) === true) {
            sendPasswordResetEmail(getAuth(), email)
                .then(function () {
                    setCurrentBlock(BLOCK_DID_RESET_PSW);
                }).catch(function (error) {
                kslog("error.login", error.code);
                if (error.code === ERROR_CODE_ACCOUNT_NOT_FOUND) {
                    setEmailError(strings.account_not_found)
                }
            });
        } else {
            setEmailError(strings.wrong_email);
        }


    }

    //логинимся с паролем и имейлом
    function firebaseLogin(email, psw) {
        setIsWaiting(true);

        //если была ошибка и не добавился id_enevoin, пробуем прикрепить еще раз
        if (getAuth().currentUser && getAuth().currentUser.photoURL===null){
            //создаем в базе - получаем id_enevoin
            dbManager.current.updateEnevoin(getAuth().currentUser.email, getAuth().currentUser.displayName, getAuth().currentUser.uid);
            return;
        }


        try {

            signInWithEmailAndPassword(getAuth(), email, psw)
                .then((userCredential) => {
                    // Signed in
                    kslog("firebaseUser", userCredential);

                    doUser(userCredential.user);
                    setIsWaiting(false);
                })
                .catch((error) => {
                    kslog("error.login", error.code);
                    if (error.code === ERROR_CODE_ACCOUNT_NOT_FOUND) {
                        setEmailError(strings.account_not_found)
                    } else if (error.code === ERROR_CODE_WRONG_PSW) {
                        setEmailError(null);
                        kslog("error.login ERROR_CODE_WRONG_PSW")
                        setPswError(strings.wrong_password)
                    } else if (error.code === ERROR_CODE_TOO_MANY_REQUEST) {
                        setEmailError(strings.overlimit_auth)
                    }
                    setIsWaiting(false);
                });
        } catch (error) {
            kslog("try er", error);
        }

    }

    //закрываем модальное окно (соглашение / политика конф)
    function modalBack() {
        setModalWindow(null)
    }


    //создали на сервере запись enevoin
    function didEnevoinCreated(response) {
        kslog("didEnevoinCreated", response)
        let id_enevoin = response["ie"];
        //вписываем id_enevoin
        let user = getAuth().currentUser;
        kslog("updateProfile",user);
        updateProfile(user, {
            photoURL: id_enevoin + ""
        }).then(() => {
            kslog("photoURL changed",id_enevoin);
            doUser(user);
            setIsWaiting(false);
        }).catch((error) => {
            kslog(error);
        });

    }

    //блок форм
    function renderBlock() {
        switch (currentBlock) {
            case BLOCK_NEW_ACCOUNT:
                return new_account;
            case BLOCK_LOGIN:
                return login_block;
            case BLOCK_RESET_PSW:
                return forgot_psw;
            case BLOCK_DID_RESET_PSW:
                return did_reset_psw;
        }
    }

    function onChangeEmail(e) {
        setEmail(e.target.value)
        setEmailError(null);
    }

    function onChangePsw(e) {
        setPsw(e.target.value)
        setPswError(null);
    }

    //получаем текст соглашения и политики конфедиц.
    function getDataUserDocs(file) {
        fetch(file + '.html'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                kslog(response)
                return response.text();
            })
            .then(function (html) {
                kslog(html);
                if (file === "sogl") setTextSogl(html);
                if (file === "policy") setTextPolicy(html);
            });
    }

    function onClickPrivacy (type){
        let objPrivacy={type, text: type==="sogl"? textSogl : textPolicy }
        setPrivacy(objPrivacy);
    }
    const modal = <ModalRoot
        activeModal={modalWindow}
        onClose={modalBack}
    >
        <ModalPage
            id="sogl"
            onClose={modalBack}
        >
            <Group style={{maxWidth: "100%", maxHeight: "100%"}}>
                <div style={{maxWidth: "100%", maxHeight: "100%"}} dangerouslySetInnerHTML={{__html: textSogl}}/>
            </Group>
        </ModalPage>
        <ModalPage
            id="policy"
            onClose={modalBack}
        >
            <Group style={{maxWidth: "100%", maxHeight: "100%"}}>
                <div style={{maxWidth: "100%", maxHeight: "100%"}} dangerouslySetInnerHTML={{__html: textPolicy}}/>
            </Group>
        </ModalPage>
    </ModalRoot>
    const leftWelcome = <div style={{padding: 60}}>



    </div>;
    const link_login = <FormLayoutGroup mode="horizontal">
        <Button mode="tertiary" stretched onClick={() => setCurrentBlock(BLOCK_LOGIN)} size="m">{strings.signin}</Button>
    </FormLayoutGroup>;

    const forgot_psw = <div>
        <FormLayout>
            <FormItem
                status={emailError ? 'error' : null}
                bottom={emailError}>
                <Input
                    maxLength={40}
                    autoComplete={"username"}
                    type="email"
                    placeholder="E-Mail"
                    name="email"
                    value={email}
                    onChange={(e) => onChangeEmail(e)}
                />
            </FormItem>
        </FormLayout>
        <FormLayoutGroup mode="horizontal">
            <Button disabled={isWaiting} stretched onClick={() => resetPsw()} size="m">{strings.forgotpsw}</Button>
        </FormLayoutGroup>
        {link_login}

    </div>
    const did_reset_psw = <div style={{textAlign: "center", color: "gray"}}>
        <div>{strings.reset_psw_sended}</div>
        {link_login}
    </div>;
    const new_account = <div>
        <FormLayout>
            {/*<input hidden/>*/}
            <FormItem>
                <Input placeholder={strings.user_name}
                       value={newAccountName}
                       onChange={(e) => setNewAccountName(e.target.value)}
                       maxLength={35}/>
            </FormItem>
            <FormItem
                status={emailError ? 'error' : null}
                bottom={emailError}
            >
                <Input
                    name="email"
                    autoComplete={"username"}
                    maxLength={40}
                    type="email"
                    placeholder="E-Mail"
                    // name="email"
                    value={email}
                    onChange={(e) => onChangeEmail(e)}
                />
            </FormItem>

            <FormItem>
                <Input
                    autoComplete="new-password"
                    value={psw}
                    onChange={(e) => onChangePsw(e)}
                    maxLength={50} type="password" placeholder={strings.password}/>
            </FormItem>
            <FormItem
                status={pswError ? 'error' : null}
                bottom={pswError}>
                <Input
                    autoComplete="new-password"
                    value={newAccountPswRepeat}
                    onChange={(e) => setNewAccountPswRepeat(e.target.value)}
                    maxLength={50} type="password" placeholder={strings.repeat_password}/>
            </FormItem>
            <FormLayoutGroup mode="horizontal">
                <Button disabled={isWaiting} stretched onClick={() => createNewAccount(dbManager.current)} size="m"> {isWaiting ?
                    <Spinner size="small"/> : strings.signup} </Button>
            </FormLayoutGroup>
            <Footer >{strings.apply_policy}<Link
                onClick={() => onClickPrivacy("sogl")}>{strings.term_of_use}</Link>, <Link
                onClick={() => onClickPrivacy("policy")}>{strings.privacy_policy}</Link>.
            </Footer>
            {link_login}
        </FormLayout>

    </div>;
    const login_block = <div>
        {/*<input hidden/>*/}
        <FormLayout>
            <FormItem
                top="E-mail"
                status={emailError ? 'error' : null}
                bottom={emailError}
            >

                <Input
                    autoComplete="username"

                    type="email"
                    placeholder="E-mail"
                    name="email"
                    value={email}
                    onChange={(e) => onChangeEmail(e)}
                />
            </FormItem>

            <FormItem
                top={strings.password}
                status={pswError ? 'error' : null}
                bottom={pswError}>
                <Input
                    autoComplete={"current-password"}
                    value={psw}
                    onChange={(e) => onChangePsw(e)}
                    type="password" placeholder={strings.password}/>
            </FormItem>
        </FormLayout>

        <FormLayoutGroup mode="horizontal">
            <Button disabled={isWaiting} id='btn_login' stretched onClick={() => login()} size="m">
                {isWaiting ?
                    <Spinner size="small"/> : strings.signin}
            </Button>
            <Button stretched style={{marginLeft: 30}} mode="secondary"
                    onClick={() => setCurrentBlock(BLOCK_NEW_ACCOUNT)} size="m">{strings.signup}</Button>

        </FormLayoutGroup>
        <FormLayoutGroup mode="horizontal">
            <Button stretched mode="tertiary" onClick={() => setCurrentBlock(BLOCK_RESET_PSW)} size="m">{strings.forgotpsw}</Button>
        </FormLayoutGroup>
    </div>;
    return (
        <div>
        <PanelHeader>  <Link href={strings.web_link}>{strings.name_link}</Link> </PanelHeader>
        <Group>
                    <div  >

                        {renderBlock()}
                        {/*<Separator style={{marginTop: 20}}/>*/}

                        {/*<FormLayoutGroup  mode="horizontal" >*/}
                        {/*  <Button stretched mode="tertiary" onClick={()=>login()} size="m">Демо</Button>*/}
                        {/*</FormLayoutGroup>*/}

                        {/*<Button mode="tertiary" onClick={login} size="m">Демо</Button>*/}
                        {/*<Button mode="tertiary" onClick={login_admin} size="m">Демо admin</Button>*/}
                        {/*<Button mode="tertiary" onClick={resetPsw} size="m">resetPsw admin </Button>*/}

                    </div>


            <DBManager
                callbackEnevoinUpdated={didEnevoinCreated}
                ref={dbManager}/>
        </Group></div>
    )
}

export default  Login;

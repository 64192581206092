import React, {useEffect, useState} from "react";
import YouTube from 'react-youtube';
import {PanelHeader, PanelHeaderBack, PanelHeaderButton, Platform, platform} from "@vkontakte/vkui";
import {Icon28MessageOutline} from "@vkontakte/icons";
import {kslog} from "../CommonHelper";
// or const { useQuill } = require('react-quilljs');
import {isMobile} from 'react-device-detect';
import 'react-quill/dist/quill.snow.css';

import 'quill/dist/quill.snow.css'; // Add css for snow theme
class Icon12ArrowDownRight extends React.Component {
    render() {
        return null;
    }
}

function OneNote({goToPage, currentObjNote, videoCommand, goBack}) {

    const [player, setPlayer] = useState(null);
    let secTmp=0;
    useEffect(() => {
        kslog("videoCommand", videoCommand)
        kslog("videoCommand player", player)
        let sec=parseInt(videoCommand.sec);

        if (player && videoCommand.sec)  {
            kslog("videoCommand seek", sec)
            // player.seekTo(0);
            player.seekTo(sec);
        }
        if (player===null) secTmp= sec;
    }, [videoCommand]);


    function onPlayerReady(event) {
        kslog("onPlayerReady",secTmp);
        setPlayer(event.target);
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
        event.target.seekTo(secTmp);

    }
    function onPlayerStateChange(event) {
        kslog("isMobile", event)
        // if (event.data == YT.PlayerState.PLAYING) {
        // }
    }

    const modules = {
        toolbar: false
    }

    kslog("isMobile", isMobile)

    const opts2 = {
        width: isMobile ? window.innerWidth : "100%",
        height: isMobile ? window.innerWidth * 9 / 16 : 500 * 9 / 16,
        // height: isCOM ? "auto" : window.innerWidth*9/16,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    return (<div  id={isMobile ? "div-video" : ""}  >
        <PanelHeader
            before={<PanelHeaderBack label="Назад" onClick={() => goBack()}/>}
            after={
                <PanelHeaderButton>
                    <Icon28MessageOutline
                        width={platform === Platform.VKCOM ? 24 : 28}
                        height={platform === Platform.VKCOM ? 24 : 28}
                    />
                </PanelHeaderButton>
            }
        >
            <div id="title-video"> {currentObjNote && currentObjNote.title} </div>

        </PanelHeader>
            {currentObjNote && currentObjNote.type===1       ?
                  <YouTube className="video-player" videoId={currentObjNote ? currentObjNote.link : ""} opts={opts2}
                onReady={onPlayerReady} onStateChange={onPlayerStateChange}

                  />

            :null}

    </div>)

}

export default OneNote;



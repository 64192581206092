import React, {useEffect, useRef, useState} from 'react';
import {
    AdaptivityProvider,
    Alert,
    AppRoot,
    Button,
    ButtonGroup,
    ConfigProvider,
    Div,
    Group, FormLayoutGroup, FormItem,
    Link,
    PanelHeader,
    PanelHeaderButton,
    Platform,
    Select,
    Spinner,
    SplitCol,
    SplitLayout,
    Title,
    useAdaptivityConditionalRender,
    usePlatform,
    View, Placeholder, ModalRoot, ModalPage,
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import Home from "./panels/Home";
import OneNote from "./panels/OneNote";
import {DBManager} from "./DBManager";
import {
    convertIdTagsToArray,
    goScrollTop,
    kslog,
    prepareTagsForSelect,
    prepareTimesAndPages,
    wwwExtention
} from "./CommonHelper";
import TextNote from "./panels/TextNote";
import {
    Icon16ArrowLeftOutline,
    Icon16ArrowRightOutline,
    Icon20UserCircleOutline,
    Icon24UserOutline
} from "@vkontakte/icons";
import Login from "./panels/Login";
import firebaseApp, {doSignOut} from "./firebase";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import Account from "./panels/Account";
import {isMobile} from 'react-device-detect';
import {strings} from "./localization";

const App = () => {
    const platform = usePlatform();
    const {viewWidth} = useAdaptivityConditionalRender();
    const [modal, setModal] = React.useState(null);
    const [popout, setPopout] = React.useState(null);
    const [authenticated, setAuthenticated] = useState(null);
    const [activePanel, setActivePanel] = useState('home');
    const [history, setHistory] = useState(["home"]);
    const [currentObjNote, setCurrentObjNote] = useState(null);
    const dbManager = useRef();
    // const refVideoNote = useRef();

    const [arListNotes, setArListNotes] = useState([]);
    const [arTimes, setArTimes] = useState([]);
    const [arDataPages, setArDataPages] = useState([]);
    const [timeIndex, setTimeIndex] = useState(0);
    const [sourceNotes, setSourceNotes] = useState(null);
    const [videoCommand, setVideoCommand] = useState("");
    const [arListNotesSource, setArListNotesSource] = useState([]);
    const [arTags, setArTags] = useState([]);
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [modalWindow, setModalWindow] = useState(null);
    const [loadingListNotes, setLoadingListNotes] = useState(true);
    const [loadingGetNote, setLoadingGetNote] = useState(false);
    const [privacy, setPrivacy] = useState({type:"",text:""});
    const [player, setPlayer] = useState(null);
    const [currentType, setCurrentType] = useState(1);
    useEffect(() => {
        doAuth();
        kslog("useEffect")
        window.addEventListener('popstate', () => goBack()); //  Добавляем обработчик события изменения истории для работы аппаратных кнопок.
        function handleKeyDown(e) {
            if (e.keyCode === 39 || e.keyCode === 37){
                let btn= document.getElementById(e.keyCode === 39 ? 'btn-nexttime' :'btn-prevtime');
                if (btn) btn.click();
            }
        }

        document.addEventListener('keydown', handleKeyDown);
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }

    }, []);

    useEffect(() => {
        kslog("use currentObjNote", timeIndex)
        if (currentObjNote && currentObjNote.list_index !== undefined) {//выбрали заметку из списка

            dbManager.current.getNote(currentObjNote.id_note);
            setLoadingGetNote(true);
            // if (currentObjNote.type === 1 || isMobile) { //если видео переходим
            //     goToPage("onenote", currentObjNote)
            // }

        }
    }, [currentObjNote]);

    useEffect(() => {
        if (privacy.text.length>0){
            setModalWindow (privacy.type);
        }
    }, [privacy]);


    //авторизация
    function getDataFromServer(typeValue, andTags) {
        dbManager.current.getListNotes(typeValue);
        if (andTags === true) dbManager.current.getTags()
    }

    //авторизация
    function doAuth() {
        const auth = getAuth(firebaseApp);
        onAuthStateChanged(auth, user => {
            kslog("onAuthStateChanged", auth)
            kslog(" doAuth", user);
            doUser(user);
        });
    }

    function doUser(user) {
        kslog("doUser",  user  );
        kslog("doUser", user && user.photoURL);
        if (user && user.photoURL !== null) { //пользователь найден - продолжаем
            setAuthenticated(1);
            kslog("вошли ОК", user);

            // if (1===2 && user.emailVerified===false) {
            //     let createdHoursBefore=(Moment().unix()-user.metadata.createdAt/1000)/60;
            //     kslog("createdTime",createdHoursBefore);
            //     setAuthenticated(AUTH_EMAIL_NOT_VERIFIED);
            // }else {
            //     // setAuthenticated(AUTH_EMAIL_NOT_VERIFIED);
            //     setAuthenticated(AUTH_SUCCESS);
            //     loadAppData();
            // }
        } else if (user && user.photoURL === null) { //идет создание
            kslog("user.photoURL === null", user);
            // doSignOut();
        } else  { //не вошли
            kslog("doUser не вошли", activePanel);
            setActivePanel("home");
            setAuthenticated(0);
        }
    }



    //сменили временную отметку
    useEffect(() => {

        if (arTimes.length > 0) {
            kslog("timeIndex seek", timeIndex)
            kslog("timeIndex seek", arTimes)
            kslog("timeIndex seek", currentObjNote)
           setVideoCommand({op: "seek", sec: arTimes[timeIndex].time_sec});
        }
    }, [timeIndex]);

    //назад по кнопке навигации
    function goBack() {
        kslog("goBack")
        let historyTmp = history;
        kslog("goBack", history)
        if (historyTmp.length > 1) {  // Если в массиве больше одного значения:
            historyTmp.pop() // Удаляем последний элемент в массиве.
            let actPanel = historyTmp[historyTmp.length - 1];

            setActivePanel(actPanel);
            kslog("nav goBack history after :", history + " | actPanel: " + actPanel);
            setTimeIndex(0)
        }
        setCurrentObjNote(null);
        setArDataPages([]);
    }
    //пришел список заметок
    function responseGetListNotes(result) {
        let arListNotesTmp = convertIdTagsToArray(result["rs"]);
        kslog("arListNotesTmp", arListNotesTmp)
        setArListNotesSource(arListNotesTmp);
        setArListNotes(arListNotesTmp);
        setLoadingListNotes(false);
    }

    //ответ метки
    function responseGetTags(result) {
        setArTags(prepareTagsForSelect(result["rs"]));
    }

    //пришли данные по заметкам
    function responseGetNote(result) {
        setLoadingGetNote(false);
        kslog("responseGetNote", result);
        let jsonNote = result.result.note;
        jsonNote = jsonNote.replace(/\n/g, "\\n");
        kslog("responseGetNote 1", JSON.parse(jsonNote).ops);

        setSourceNotes(JSON.parse(jsonNote));
        let arResTmp=prepareTimesAndPages(jsonNote);

        setArTimes(arResTmp[0]);
        setArDataPages(arResTmp[1]);

        if (currentObjNote.type === 1 || isMobile) { //если видео переходим
            if (activePanel === "home"){
                if (arResTmp[0].length>0) setVideoCommand({op: "seek", sec: arResTmp[0][0].time_sec});
                kslog("responseGetNote","goToPage")
                goToPage("onenote", currentObjNote)
            }
        }
    }

    //переходим к окну
    const goToPage = (name, params) => {
        if (!params.no_history) {
            window.history.pushState({panel: name}, name);
            let historyTmp = history;
            historyTmp.push(name)
            setHistory(historyTmp);//[...history, name]
            kslog("params", historyTmp)
        }
        setActivePanel(name);
    }
    //выбрали время для перехода
    function onClickGoTime(where) {
        kslog("onClickGoTime",timeIndex)
        let timeIndexTmp=timeIndex<arTimes.length ? timeIndex : 0;
        if ((timeIndexTmp > 0 && where === -1) || (timeIndexTmp < arTimes.length - 1 && where === 1)) {
            setTimeIndex(timeIndexTmp + where);
        }
    }
    //следующий материал
    function onClickNextVideo() {
        let indexTmp = currentObjNote.list_index < currentObjNote.list_notes.length - 1 ? currentObjNote.list_index + 1 : 0;
        let nextNote = currentObjNote.list_notes[indexTmp];
        nextNote.list_notes = currentObjNote.list_notes;
        nextNote.list_index = indexTmp;
        nextNote.no_history = true;
        setCurrentObjNote(nextNote);
    }

    function onChangeTime(option) {
        kslog("onChangeTime", option.value);
        if (option.value) {
            setTimeIndex(parseInt(option.value));
        }
    }

    function onClickProfile() {
        goToPage("account", {});
    }

    function renderNextTitle() {
        if (currentObjNote && currentObjNote.list_notes) {
            let indexTmp = currentObjNote.list_index < currentObjNote.list_notes.length - 1 ? currentObjNote.list_index + 1 : 0;
            return currentObjNote.list_notes[indexTmp].title;
        }
    }

    function closeDeletion() {
        setPopout(null)
    }

    function deleteNote(item) {
        kslog("deleteNote currentObjNote", item);
        dbManager.current.deleteNote(item.id_note, item.type);
    }
    //закрываем модальное окно (соглашение / политика конф)
    function modalBack() {
        setModalWindow(null)
    }
    const modalView = <ModalRoot
        activeModal={modalWindow}
        onClose={modalBack}
    >
        <ModalPage
            id="sogl"
            onClose={modalBack}
        >
            <Group style={{maxWidth: "100%", maxHeight: "100%"}}>
                <div style={{maxWidth: "100%", maxHeight: "100%"}} dangerouslySetInnerHTML={{__html:privacy && privacy.text}}/>
            </Group>
        </ModalPage>
        <ModalPage
            id="policy"
            onClose={modalBack}
        >
            <Group style={{maxWidth: "100%", maxHeight: "100%"}}>
                <div style={{maxWidth: "100%", maxHeight: "100%"}} dangerouslySetInnerHTML={{__html:privacy && privacy.text}}/>
            </Group>
        </ModalPage>
    </ModalRoot>
    const openDeletion = (item) => {

        setPopout(
            <Alert
                actions={[
                    {
                        title: strings.cancel,
                        autoClose: true,
                        mode: 'cancel',
                    },
                    {
                        title: strings.delete,
                        autoClose: true,
                        mode: 'destructive',
                        action: () => deleteNote(item),
                    },
                ]}
                actionsLayout="horizontal"
                onClose={() => closeDeletion()}
                header={strings.deletion}
                text={strings.confirm_deletion}
            />
        );
    }
    const timeNavigator = <div id="div-nav-time"><ButtonGroup mode="horizontal" stretched>
        <Select
            onChange={(e) => onChangeTime(e.target)}
            // placeholder="Не выбран"
            options={arTimes}
            value={timeIndex>=arTimes.length ? 0 : timeIndex}
        />
        <Button size="l" mode={"tertiary"} disabled before={(timeIndex < arDataPages.length ? (timeIndex + 1) : 1) + "/" + arDataPages.length }/>
        <Button size="l" id="btn-prevtime" onClick={() => onClickGoTime(-1)} mode={"tertiary"}
                before={<Icon16ArrowLeftOutline/>}/>
        <Button size="l" id="btn-nexttime" onClick={() => onClickGoTime(1)} mode={"tertiary"}
                before={<Icon16ArrowRightOutline/>}/>
    </ButtonGroup></div>;
    const oneNoteBlock =
        <div id={isMobile ? "div-notes" : ""}>
        {activePanel === "onenote" || (activePanel === "home" && isMobile === false && authenticated === 1) ?
            <>
                {currentObjNote && currentObjNote.type === 0 ? <Div id="link-text-note"><a target="_blank"
                                                                                           href={currentObjNote.link}>{currentObjNote.link}</a></Div> : null}
                {
                    loadingGetNote===true
                        ?  <Spinner size="medium" style={{marginTop: 30}}/>
                        :
                        <TextNote arTimes={arTimes} setTimeIndex={setTimeIndex} timeIndex={timeIndex} arDataPages={arDataPages}/>
                }

            </>

            : null}
    </div>

    const instruction = <div>
        <div id="div-instruction">


                {strings.create_note_with} <a target="_blank"
                                              href={wwwExtention}>
                {strings.chrome_ext}</a>

            {strings.instruction}

            <p><a style={{marginBottom: "120px"}} href="https://t.me/enevator">{strings.feedback}</a></p>

        </div>

    </div>


    const isVKCOM = platform === Platform.VKCOM;
    return (
        <AppRoot>
            {/*scheme={scheme}*/}
            <ConfigProvider appearance="light">
                <AdaptivityProvider>
                    <AppRoot>
                        <SplitLayout
                            style={{justifyContent: 'center'}}
                            header={!isVKCOM && <PanelHeader separator={false}/>}
                            popout={popout}
                            modal={modalView}
                        >
                            <SplitCol width="100%" maxWidth="500px" stretchedOnMobile>

                                <View activePanel={activePanel}>
                                    <Account id="account" goBack={goBack}/>
                                    <div id='home'>
                                        {authenticated === 1
                                            ?
                                            <>
                                                <PanelHeader before={
                                                    <PanelHeaderButton aria-label="header"  style={{left:4}} onClick={() => onClickProfile()}>
                                                       <Icon24UserOutline />
                                                    </PanelHeaderButton>
                                                }

                                                > <Link style={{left:10}}  href="https://localhost:10888/">MemoTube</Link> </PanelHeader>

                                                <Home
                                                    getDataFromServer={getDataFromServer}
                                                    openDeletion={openDeletion}
                                                    deleteNote={deleteNote}
                                                    selectedTags={selectedTags}
                                                    setSelectedTags={setSelectedTags}
                                                    setCurrentObjNote={setCurrentObjNote}
                                                    arListNotesSource={arListNotesSource}
                                                    arListNotes={arListNotes}
                                                    setArListNotes={setArListNotes}
                                                    arTags={arTags}
                                                    loadingListNotes={loadingListNotes}
                                                    currentType={currentType}
                                                    setCurrentType={setCurrentType}
                                                    goToPage={goToPage}/>

                                            </>
                                            :
                                            authenticated === 0
                                                ? <Login id='login' setPrivacy={setPrivacy} doUser={doUser}/>
                                                : <Spinner size="medium" style={{marginTop: 230}}/>
                                        }
                                    </div>


                                    <div id='onenote'>
                                        <div id={isMobile ? "div-main" : ""}>
                                            <OneNote  player={player} setPlayer={setPlayer}
                                                        goBack={goBack} videoCommand={videoCommand} goToPage={goToPage}
                                                     currentObjNote={currentObjNote}/>
                                            {/*{arListNotes && arListNotes.length > 1 && timeIndex === arTimes.length - 1*/}
                                            {/*    ?*/}

                                                        <FormItem top={strings.next}>
                                                            <Button mode="secondary" stretched onClick={() => onClickNextVideo()}>
                                                                {renderNextTitle()}
                                                            </Button>
                                                        </FormItem>

                                                {/*// : null}*/}
                                            {isMobile ?
                                                <>
                                                    {currentObjNote && currentObjNote.type === 1 ? timeNavigator : null}
                                                    {oneNoteBlock}
                                                </>
                                                : null
                                            }

                                        </div>
                                    </div>


                                </View>


                            </SplitCol>
                            {isMobile ? null :

                                <SplitCol width="100%" maxWidth="500px" stretchedOnMobile autoSpaced>
                                    <View activePanel={"notepanel"}>
                                        <div id='notepanel'>
                                            <PanelHeader>{strings.summary}</PanelHeader>

                                            <Group>
                                                {activePanel === "onenote" ? timeNavigator : null}
                                                {oneNoteBlock}
                                                {activePanel === "account" || (activePanel === "home" && authenticated===0) ? instruction : null}
                                            </Group>
                                        </div>
                                    </View>
                                </SplitCol>
                            }
                        </SplitLayout>
                    </AppRoot>
                </AdaptivityProvider>
            </ConfigProvider>
            <DBManager ref={dbManager} callbackGetListNotes={responseGetListNotes}
                       callbackGetTags={responseGetTags} callbackGetNote={responseGetNote}/>
        </AppRoot>
    );
}

export default App;

import LocalizedStrings from 'react-localization';
import React from "react";
import {Footer, Link} from "@vkontakte/vkui";

export const strings = new LocalizedStrings({
    en:{
        video:"Video",
        text:"Text",
        filter:"Filter",
        chooseNote:"Choose a note",
        no_records:"No items",
        create_note_with: "1. Install ",
        chrome_ext:"the Chrome browser extension",
        summary:"Summary",
        signout:"Sign out",
        feedback:"Developer (Telegram)",
        account: "Account",
        deletion:"Delete",
        confirm_deletion: "Are you sure you want to delete this item?",
        delete:"Delete",
        cancel:"Cancel",
        next:"Next:",
        go:"Go",
        instruction:"\n2. Go to Youtube or any website.\n3. Open the extension and take notes.\n4. Use the website to view your notes.",
        signin:"Sign in",
        forgotpsw:"Reset password",
        signup:"Create account",
        name_link:"MemoTube",
        web_link:"https://memotube.enapp.ru",
        password:"Password",
        repeat_password: "Repeat password",
        user_name:"Your name",
        overlimit_auth:"Too many requests, try later",
        wrong_password:"Wrong password",
        account_not_found:"Account not found",
        wrong_email:"Invalid email format",
        email_exits:"An account with this E-Mail already exists",
        dif_passwords:"Password mismatch",
        psw_more_6:"Password must be 6 or more characters",
        term_of_use:"Terms of Use",
        privacy_policy:"Privacy Policy",
        apply_policy:"By registering, you agree to the ",
        reset_psw_sended:"A password reset link has been sent to your email. Follow it and create a new password."
    },
    ru: {
        video:"Видео",
        text:"Текст",
        filter:"Фильтр",
        chooseNote:"Выберите материал",
        no_records:"Записи не найдены",
        create_note_with: "1. Установите ",
        chrome_ext:"расширения для Chrome браузера",
        summary:"Конспект",
        signout:"Выйти из аккаунта",
        feedback:"Связаться с разработчиком (Telegram)",
        account: "Аккаунт",
        deletion:"Удаление записи",
        confirm_deletion: "Вы уверены, что хотите удалить эту запись?",
        delete:"Удалить",
        cancel:"Отмена",
        next:"Далее:",
        go:"Перейти",
        instruction:"\n2. Перейдите на Youtube или любой сайт.\n3. Откройте расширение и делайте заметки, конспектируйте.\n4. Для просмотра конспекта используйте сайт.",
        signin:"Войти",
        forgotpsw:"Забыли пароль",
        signup:"Регистрация",
        name_link:"MemoTube",
        web_link:"https://memotube.enapp.ru",
        password:"Пароль",
        repeat_password: "Повторите пароль",
        user_name:"Ваше имя",
        overlimit_auth:"Слишком много запросов, попробуйте позже",
        wrong_password:"Неверный пароль",
        account_not_found:"Аккаунт не найден",
        wrong_email:"Неверный формат E-Mail",
        email_exits:"Аккаунт с таким E-Mail уже существует",
        dif_passwords:"Пароли не совпадают",
        psw_more_6:"Пароль должен быть 6 и более символов",
        apply_policy: "Нажимая кнопку «Создать аккаунт», вы принимаете ",
        term_of_use:"пользовательское соглашение",
        privacy_policy: "политику конфиденциальности",
        reset_psw_sended:"Ссылка на сброс пароля отправлена на почту. Перейдите по ней и создайте новый пароль."

}
});
